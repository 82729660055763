.navbar-enter {
  opacity: 1;
  transform: translateX(-100%);
}

.navbar-enter-active {
  opacity: 1;
  transition: all 600ms ease-out;
  transform: translateX(0);
}

.navbar-exit {
  opacity: 1;
}

.navbar-exit-active {
  opacity: 1;
  transition: all 600ms ease-out;
  transform: translateX(-100%);
}
